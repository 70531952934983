<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left">La Mora Glamour</h1>
          <p class="text-paragraph">
            Il primo brand in Italia a produrre una linea di abbigliamento esclusivamente in stile casual chic. Il marchio
            si distingue per la produzione autonoma dei propri capi, garantendo qualità e unicità nel design.
          </p>
          <p class="text-paragraph">
            Il brand è verticalizzato sul target donna tra i 40 e i 65 anni, valorizzando la femminilità ed esaltando le
            forme con taglie proporzionate in base alle misure.
          </p>
          <p class="text-paragraph">
            L'esperienza delle clienti viene quindi esaltata dalla scelta di prodotti per ogni vestibilità e dalla
            semplicità delle proposte di Giusy.
          </p>
        </div>
        <div class="col-lg-6 mt-4 mb-3">
          <a target="_blank" href="https://www.lamoraglamour.com/">
            <img class="img-fluid border-brand" src="/img/lamora2.png">
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Esigenza</h4>
          <TextScroller :class="'digital1'" :text="'Nella fase iniziale il brand, a causa della grande quantità di ordini seguiti su Whatsapp, aveva problemi di gestione e perdita di dati nel seguire l\'intero processo di elaborazione dell\'ordine, packaging e spedizione.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="cardblock">
            <h1 class="mb-5 title-section" data-aos="fade-down">Obiettivo</h1>
            <p class="text-paragraph">
              Data la crescente quantità di ordini ricevuti su WhatsApp, l'azienda necessitava urgentemente di un gestionale
              capace di raccogliere e gestire in modo efficiente tutti i dati degli ordini ricevuti. La soluzione ideale veniva definita in una piattaforma versatile,
              servendo sia il canale online che quello offline, oltre alle vendite degli store fisici (strategia omnichannel).<br><br>Si rendeva quindi indispensabile una gestione centralizzata del magazzino per ottimizzare tutti
              i canali di vendita (sito web, app e punti cassa). Inoltre, il cliente ha poi richiesto di sviluppare un'app innovativa per ampliare ulteriormente
              il business e raggiungere un pubblico ancora più vasto.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Le nostre soluzioni</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione gestionale in cloud</h2>
          <p class="text-paragraph">
            Abbiamo progettato insieme al cliente e realizzato con il nostro team di sviluppo e DevOps un gestionale
            omnichannel in cloud personalizzato in base alle esigenze specifiche del cliente.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+123% Data</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione e sviluppo app</h2>
          <p class="text-paragraph">
            Siamo riusciti a realizzare un'app per IOS e Android con un sistema integrato di scelta automatica della taglia
            per ogni prodotto in base alle misure inserite dai clienti in fase di registrazione.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+16.000 Users</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione sistema POS</h2>
          <p class="text-paragraph">
            Per terminare il processo di trasformazione di questo brand in una omnichannel company abbiamo progettato e realizzato
            un sistema di cassa che parlasse con il magazzino centralizzato del gestionale in cloud.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right"> +157% Sales</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>