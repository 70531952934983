<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="title-section mb-5" data-aos="fade-left">Our corporate headquarter</h1>
          <p class="text-paragraph">
            An office in the historic center of L'Aquila, a small city in Italy, that creates a stimulating and innovative working atmosphere.
            Conceived as an apartment that can make the work team feel at home.
            <!--Inoltre la sede è dotata di un grande giardino che permette di organizzare meeting lavorativi, piccoli eventi e meetup-->
          </p>
        </div>
        <div class="col-lg-6">
          <video width="100%" poster="/img/poster.png" class="video" controls ref="videocasasaturno" title='videocasasaturno'>
            <source src="/video/saturno-consulting.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Casa Saturno</h4>
          <TextScroller :class="'casa1'" :text="'Casa Saturno is equipped with a kitchen, meeting room and operative rooms with LCD screen. A 200 square meter apartment that allows our collaborators to fully experience the company and exchange ideas, skills, training and much more.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img src="/img/casasaturno/sala.jpg" alt="">
        </div>
        <div class="col-lg-6">
          <img src="/img/casasaturno/salaop1.jpg" alt="">
          <img src="/img/casasaturno/salaop2.jpg" alt="">
        </div>
        <div class="col-lg-12">
          <img src="/img/casasaturno/cucina.jpg" alt="">
          <img src="/img/casasaturno/giardino.jpg" alt="">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TextScroller from "@/components/TextScroller.vue";

export default {
  name: "CasaSaturno",
  components: {TextScroller}
}
</script>

<style scoped>
img {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
</style>