<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <h1 class="title-section mb-5" data-aos="fade-left">I nostri prodotti software</h1>
          <p class="text-paragraph">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s.
          </p>
        </div>
        <div class="col-lg-7">
          <div class="fl-block" id="particles-software" style="height: 400px;"></div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Come lo facciamo</h4>
          <TextScroller :class="'products1'" :text="'However, for brands, AI often feels distant and confined to expert developers, especially in eCommerce where reliance on off-the-shelf SAAS products can lead to outdated technology and a significant gap between innovation and accessibility. We have closed that gap.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-6">
              <div class="cardblock border" style="height:350px">
                <h1 class="mb-5" data-aos="fade-down">FFriday</h1>
                <p class="text-paragraph">
                  Il primo coach digitale per la tua azienda per tenere sotto controllo costi e ricavi
                  e ti aiuta a migliorare le performance della tua attività.
                </p>
                <button class="btn btn-outline-secondary">Scopri</button>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="cardblock border" style="height:350px">
                <h1 class="mb-5" data-aos="fade-down">WooConnector</h1>
                <p>
                  Un plug-in ufficiale per WordPress che
                  permette di collegare il tuo gestionale Cassa in Cloud al sito e-commerce WooCommerce.
                </p>
                <button class="btn btn-outline-secondary">Scopri</button>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="cardblock border" style="height:350px">
                <h1 class="mb-5" data-aos="fade-down">Distinta base</h1>
                <p>
                  Un software pensato per lavorare
                  insieme al gestionale Cassa in Cloud,
                  impiegato per monitorare l’utilizzo degli ingredienti che compongono una ricetta.
                </p>
                <button class="btn btn-outline-secondary">Scopri</button>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="cardblock border" style="height:350px">
                <h1 class="mb-5" data-aos="fade-down">Amico POS</h1>
                <p>
                  Il software all-in-one per gestire l'intero flusso di vendita: dall'ecommerce al tuo negozio fisico.
                </p>
                <button class="btn btn-outline-secondary">Scopri</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'particles.js'
import TextScroller from "@/components/TextScroller.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Products",
  components: {TextScroller},
  mounted() {
    this.initParticles();
  },
  methods : {
    initParticles() {
      window.particlesJS('particles-software', {
        "particles": {
          "number": {
            "value": 200,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 1,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 3,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 6,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": false,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 100,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      });
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.arrow {
  padding-top: 0;
}

.arrow:hover {
  fill: #fff;
}

.titlesection {
  margin-top: 70px !important;
}

@media screen and (min-width: 0px) and (max-width: 768px) {

  .titlesection {
    font-size: 50px;
  }

  .text {
    font-size: 15px;
  }

  .description {
    font-size: 18px;
  }

  .secondtitle {
    margin-top: 70px !important;
    font-size: 35px;
    font-weight: 700;
  }

  .card-img-top {
    width: 50%;
  }

  #box1, #box2, #box3 {
    margin-top: 50px;
  }

  #box2, #box3 {
    animation-delay: 1s;
  }

}
</style>