<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="title-section mb-5" data-aos="fade-left">Case studies</h1>
          <p class="text-paragraph">
            In questa sezione vi presentiamo una selezione di casi di successo che evidenziano il nostro impegno e la nostra
            competenza nello sviluppo di soluzioni software innovative. Questi esempi illustrano come abbiamo aiutato
            le aziende a raggiungere i loro obiettivi attraverso l'implementazione di tecnologie omnichannel avanzate,
            l'ottimizzazione dei processi operativi e la creazione di piattaforme personalizzate.
          </p>
          <p class="text-paragraph mb-5">
            Scoprite come le
            nostre soluzioni hanno trasformato le sfide dei nostri clienti in valori tangibili e durevoli.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-3">
          <router-link to="/bikinilovers">
            <img class="img-fluid border-brand" src="/img/bkl2.png">
          </router-link>
        </div>
        <div class="col-lg-4 mb-3">
          <router-link to="/lamoraglamour">
            <img class="img-fluid border-brand" src="/img/lamora2.png">
          </router-link>
        </div>
        <div class="col-lg-4 mb-3">
          <router-link to="/vinoecioccolato">
            <img class="img-fluid border-brand" src="/img/vec.png">
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>