<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left">Bikini Lovers</h1>
          <p class="text-paragraph">
            Bikini Lovers è un brand italiano che realizza costumi da bagno femminili, una filosofia di stile guidata dalla
            passione.
          </p>
          <p class="text-paragraph">
            Fondato nel 2012 a Pompei, il marchio ha rapidamente guadagnato fama grazie alla sua estetica distintiva
            e all'alta qualità dei materiali.
          </p>
          <p class="text-paragraph">
            La continua ricerca di tessuti all'avanguardia, un'attenzione meticolosa ai processi produttivi e una particolare
            attenzione alla vestibilità esaltano la bellezza femminile e sono i pilastri su cui è costruita l'identità
            dell'azienda.
          </p>
        </div>
        <div class="col-lg-6 mt-4 mb-3">
          <a target="_blank" href="https://www.bikinilovers.it/">
            <img class="img-fluid border-brand" src="/img/bkl2.png">
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Esigenza</h4>
          <TextScroller :class="'digital1'" :text="'Nella fase iniziale il brand aveva un sito web con diversi problemi di usabilità. Inoltre nel settore retail dei costumi da bagno la particolarità della gestione dei prodotti necessitava di una piattaforma software altamente personalizzata.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="cardblock">
            <h1 class="mb-5 title-section" data-aos="fade-down">Obiettivo</h1>
            <p class="text-paragraph">
              Data la crescente quantità di ordini ricevuti e la gestione di prodotti a pacchetto con taglie miste, l'azienda aveva bisogno di un gestionale
              idoneo a raccogliere in modo efficiente i dati degli ordini ricevuti. La soluzione ideale veniva definita in una piattaforma versatile,
              creata ad hoc, capace di governare sia il canale online che quello offline, oltre a tutto il controllo delle vendite nei cinque store fisici
              presenti in Italia (strategia omnichannel).<br><br>Abbiamo reso quindi indispensabile una gestione centralizzata del magazzino per ottimizzare
              le vendite su tutti i canali di business (sito web e punti cassa negli store). Il cliente ha poi richiesto di sviluppare e creare un'app dedicata
              per ampliare ulteriormente la sua forza commerciale e raggiungere un pubblico ancora più vasto.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Le nostre soluzioni</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione gestionale in cloud</h2>
          <p class="text-paragraph">
            Abbiamo progettato e realizzato con il nostro team di sviluppo e DevOps un gestionale
            omnichannel in cloud personalizzato in base alle esigenze specifiche del cliente.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+112% Data</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione nuovo sito web</h2>
          <p class="text-paragraph">
            Abbiamo progettato e realizzato un sito web con tecnologia reattiva
            che permette la vendita dei prodotti con magazzino centralizzato.
            Il sito è altamente responsivo e adatto al target definito di clienti per una
            User Experience ideale.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+78% UX/UI</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione e sviluppo app</h2>
          <p class="text-paragraph">
            Abbiamo realizzato un'app per IOS e Android per potenziare il brand awareness e rendere
            l'esperienza di acquisto integrata e più intuitiva con un semplice click.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+23.000 Users</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione sistema POS</h2>
          <p class="text-paragraph">
            Per completare il processo di trasformazione di questo brand in una vera e propria omnichannel company
            abbiamo progettato e realizzato un sistema di cassa che comunicasse perfettamente con il magazzino
            centralizzato del gestionale in cloud.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right"> +92% Sales</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>