<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left">Facciamo trasformazione digitale</h1>
          <p class="text-paragraph">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s.
          </p>
        </div>
        <div class="col-lg-7 mb-3">
          <BoxParticles :height="400"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Come lo facciamo</h4>
          <TextScroller :class="'digital1'" :text="'However, for brands, AI often feels distant and confined to expert developers, especially in eCommerce where reliance on off-the-shelf SAAS products can lead to outdated technology and a significant gap between innovation and accessibility. We have closed that gap.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="cardblock" style="height: 400px;">
            <h1 class="mb-5" data-aos="fade-down">Gestionali in Cloud</h1>
            <p class="text-paragraph">
              Costruiamo una vasta gamma di software esclusivamente
              in cloud per numerose applicazioni e in diversi settori.<br> Dal retail, alla GDO passando per il fashion fino alla ristorazione.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="height: 400px;">
            <h1 class="mb-5" data-aos="fade-down">Soluzioni per la vendita</h1>
            <p class="text-paragraph">
              Siamo in grado di operare lungo tutta la filiera di vendita. Le nostre soluzioni multicanale
              coprono la vendita online e in negozio con la facilità di un unico centro di comando.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="height: 400px;">
              <h1 class="mb-5" data-aos="fade-down">Digitale su misura</h1>
              <p class="text-paragraph">
                Software, plugin, integrazioni con software terzi, API? Il nostro pane quotidiano per qualsiasi settore.
                Sviluppiamo backend con API e applicazioni per piattaforme iOS e Android.
              </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">La nostra specialità</h4>
          <TextScroller :class="'digital2'" :text="'Siamo specializzati nella realizzazione di soluzioni software per la vendita in cloud, sia di tipologia SaaS e sia con prodotti personalizzati interfacciati con l\'hardware fiscale, in modo da gestire le vendite in modalità omnichannel.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Alcuni casi <br>di successo</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-5">
          <h1 class="title-data" data-aos="fade-right"> 9 - 20%</h1>
        </div>
        <div class="col-lg-7">
          <h2 class="title" data-aos="fade-left">La Mora Glamour</h2>
          <p class="text-paragraph">
            Whether it’s improving conversion rates, increasing average order value (AOV), or optimizing margins, your brands own AI system can effectively target it and address it.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-5">
          <h1 class="title-data" data-aos="fade-right">1 - 2 Weeks</h1>
        </div>
        <div class="col-lg-7">
          <h2 class="title" data-aos="fade-left">Bikinilovers</h2>
          <p class="text-paragraph">
            This is our commitment. Our products are designed for a rapid start, providing you a comprehensive set of tools, including Data Set Management, an eComm-ready Model Library, and Experience Deployment. Each product is a self-contained toolkit for building and generating fast results with your AI.
          </p>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-5">
          <h1 class="title-data" data-aos="fade-right">0 - 60 min</h1>
        </div>
        <div class="col-lg-7">
          <h2 class="title" data-aos="fade-left">Enjoy American Market</h2>
          <p class="text-paragraph">
            Whether it’s improving conversion rates, increasing average order value (AOV), or optimizing margins, your brands own AI system can effectively target it and address it.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">I nostri partners</h1>
          <p class="text-paragraph">Per fare prodotti digitali innovativi è importante avere solidi partners.</p>
        </div>
        <div class="col-lg-4">
          <div class="cardblock">
            <div class="body">
              <div class="cardmedia">
              <img class="img-fluid" src="/img/partners/teamsystem.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock">
            <div class="body">
              <div class="cardmedia">
                <img class="img-fluid" src="/img/partners/cassaincloud.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="cardblock" style="min-height: 200px;">
            <div class="body">
              <div class="cardmedia">
                <img class="img-fluid" src="/img/partners/nabodgital.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>