<template>
  <section class="wrappage">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3">
          <h1 class="title-section mb-5" data-aos="fade-left">Vino & Cioccolato</h1>
          <p class="text-paragraph">
            Vino & Cioccolato è un'azienda leader nella vendita di pregiati vini, champagne raffinati, distillati di alta qualità,
            delizioso cioccolato artigianale, infusi aromatici e altre prelibatezze alimentari.
          </p>
          <p class="text-paragraph">
            Ogni prodotto è selezionato con cura per offrire solo il meglio della tradizione e dell'innovazione enogastronomica.
          </p>
          <p class="text-paragraph">
            Con un'attenzione particolare ai dettagli e una passione per la qualità, i titolari operano le migliori scelte per garantire
            un'esperienza unica ad ogni cliente attraverso selezioni esclusive di prodotti.
          </p>
        </div>
        <div class="col-lg-6 mt-4 mb-3">
          <a target="_blank" href="https://vinoecioccolato.it/">
            <img class="img-fluid border-brand" src="/img/vec.png">
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-black">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mt-5 mb-5">
          <h4 class="text-grey mb-4">Esigenza</h4>
          <TextScroller :class="'digital1'" :text="'Con la varietà di prodotti proposta e la conseguente mole di acquisto da parte dei clienti, i bisogni primari dell\'azienda sono stati la diversificazione dei canali di vendita unitamente alla gestione attiva di tutti i dati raccolti.'"/>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="cardblock">
            <h1 class="mb-5 title-section" data-aos="fade-down">Obiettivo</h1>
            <p class="text-paragraph">
              In seguito alle specifiche richieste del cliente abbiamo definito la strategia di business omnicanale realizzando un ecosistema
              verticalizzato sulla parte online con app, gestionale in cloud e sito e-commerce. Abbinando tutto questo agli ottimi numeri dello
              store fisico di Vino & Cioccolato abbiamo valorizzato completamente il brand del cliente e seguito poi la sua naturale evoluzione
              verso una crescita costante.<br><br>
              Il vero valore è stato quello di trasformare la grande quantità di dati puntando su una strategia di fidelizzazione dei clienti
              e su offerte personalizzate in base alle loro abitudini di acquisto. La gestione unificata del tutto ha permesso un'interazione smart
              ed intuitiva centralizzando le vendite del cliente all'interno di una piattaforma dove statistiche e report indicano la strada da seguire.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="wrapper bg-white">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col-lg-12">
          <h1 class="title-section" data-aos="fade-left">Le nostre soluzioni</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione gestionale in cloud</h2>
          <p class="text-paragraph">
            Abbiamo progettato insieme al cliente e realizzato con il nostro team di sviluppo e DevOps un gestionale
            omnichannel in cloud personalizzato in base alle esigenze specifiche del cliente.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+181% Data</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione e sviluppo app</h2>
          <p class="text-paragraph">
            Abbiamo realizzato un'app per IOS e Android che permette anche la gestione degli ordini di tipo delivery
            per consegne entro un'ora o ritiro dell'ordine in sede.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right">+11.000 Users</h1>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-lg-6">
          <h2 class="title" data-aos="fade-left">Realizzazione sistema POS</h2>
          <p class="text-paragraph">
            Seguendo l'evoluzione commerciale dell'azienda, abbiamo progettato e realizzato
            un sistema di cassa che dialoga con il magazzino centralizzato del gestionale in cloud.
          </p>
        </div>
        <div class="col-lg-6">
          <h1 class="title-data title-section" data-aos="fade-right"> +72% Sales</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BoxParticles from "@/components/BoxParticles.vue";
import TextScroller from "@/components/TextScroller.vue";
export default {
  /* eslint-disable */
  name: "Digital",
  components: {TextScroller, BoxParticles},
  mounted() {

  },
  methods : {

  }
}
</script>

<style scoped>

</style>