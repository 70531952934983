<template>
  <form id="scrollform">
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-danger" v-for="(error,index) in errors" :key="index">
          {{ error }}
        </div>
        <div v-if="message" class="alert alert-success">
          {{ message }}
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
            <label>Name</label>
            <input type="text" name="name" v-model="contact.name" class="form-control form-control-lg">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
            <label>Email</label>
            <input type="email" name="email" v-model="contact.email" class="form-control form-control-lg">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
            <label>Phone</label>
            <input type="text" name="phone" v-model="contact.phone" class="form-control form-control-lg">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Subject</label>
          <select class="form-control form-control-lg" v-model="contact.subject">
            <option :value="null" disabled selected="selected">Select an object</option>
            <option value="Digital/Software">Digital/Software</option>
            <option value="Artificial Intelligence">Artificial Intelligence</option>
            <option value="Distintabase">Distintabase</option>
            <option value="WooConnector">WooConnector</option>
            <option value="AmicoPOS">AmicoPOS</option>
            <option value="FFriday">FFriday</option>
            <option value="Industrial">Industrial</option>
            <option value="Altro">Other</option>
          </select>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
            <label>Message</label>
            <textarea name="message" v-model="contact.message" class="form-control form-control-lg" rows="6"></textarea>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-check">
          <input v-model="contact.privacy" class="form-check-input" type="checkbox" value="on" id="privacy">
          <label class="form-check-label" for="privacy">
            I agree your <a target="_blank" href="#">privacy policy</a>
          </label>
        </div>
      </div>
      <div class="col-lg-12 mt-5 float-right">
        <div class="form-group">
          <button class="btn btn-lg btn-outline-dark" type="button" v-on:click="sendMail()">Send</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
export default {
  name: 'FormContact',
  data() {
    return {
      contact: {
        name: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
        privacy: null
      },
      errors: null,
      message: null
    }
  },
  methods : {
    async sendMail() {
      this.errors = [];
      if (!this.contact.name || !this.contact.phone || !this.contact.email || !this.contact.subject || !this.contact.message || !this.contact.privacy) {
        this.errors.push('Attention: there are empty fields or you must accept our privacy policy.');
        this.scrollTo("scrollform");
      }else{
        this.$store.commit('showSpinner');
        axios.post(
          'https://saturnoconsulting.com/mail/mail.php',
          this.contact
        ).then((result) => {
          this.$store.commit('hideSpinner');
          this.message = result.data.message;
          this.scrollTo("scrollform");
        }).catch(error => {
          this.$store.commit('hideSpinner');
          this.errors.push(error.response.data.error);
          this.scrollTo("scrollform");
          console.error("Error:", error.message);
        });
      }
    },
    scrollTo(id){
      const el = document.getElementById(id);
      if (el) {
        window.scrollTo(0, el.offsetTop - 150)
      }
    }
  }
}
</script>

<style scoped>

a{
  color: #7a7a7a;
}

.form-control-lg, .form-control {
  color: #0dcaf0;
  background-color: transparent;
}

form label{
  color: #7a7a7a;
  font-weight: normal;
  margin-bottom: 10px;
}

.form-group{
  margin-bottom: 10px;
}

.form-control{
  color: #000;
}
</style>